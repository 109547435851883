<template>
    <div class="news-item">
      <h3>{{ title }}</h3>
      <p>{{ content }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: ['title', 'content']
  }
  </script>