<template>
  <div class="home-page">
    <section class="home-page__head">
      <div class="home-page__head__left">
        <h1 class="home-page__head__title">milometaute</h1>
        <p class="home-page__head__text">Product Designer</p>
      </div>
      <div class="home-page__head__right">
        <p>+10 años diseñando interfaz de usuario, aprendiendo y creando experiencias digitales.</p>
        <span>Medellín, Colombia</span>
        <br>
        <a href="https://www.linkedin.com/in/milometaute/" target="_blank" class="home-page__head__right__button">Contactar en Linkedin</a>
      </div>
    </section>

    <section class="home-page__body">
      <div class="home-page__body__columns" v-for="(item, index) in items" :key="index">
        <img :src="item.image" alt="" class="home-page__body__columns__image">
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </div>
    </section>
    <section class="home-page__playground">
      <h3>PlayGround</h3>
      <span>Práctica con VueJS que realizo en mi tiempo libre.</span>
      <br>
      
      <div class="home-page__playground__experiment">
        <h5>Componente con lista de atributos en VueJS:</h5>
        <news-item class="home-page__body__news-item" title="Nuevas noticias" content="Contenido de las noticias aquí cambia" />
      </div>
      
    </section>
  </div>
</template>

<script>
import NewsItem from '../components/NewsItem.vue'

export default {
  name: 'HomePage',
  components: {
    NewsItem
  },
  data() {
    return {
      message: 'Hello, this is a dynamic message from Home',
      items: [
        { title: 'Responsive Layout', description: 'Jugando un poco en Figma con un autolayout que se adapta en diferentes dispostivos.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15496154/media/9038def78fc75602977974f656a797df.gif' },
        { title: 'Composición Hero', description: 'Construyendo en Figma, usando 50% del layout un Bento Grid y texto en el otro 50%.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15459826/media/c3af271b58e97cbba375e4134f0ec2ad.png' },
        { title: 'Presentación Producto', description: 'Una transición para cargar estilos de cascos y explorar sus características. ', image: 'https://cdn.dribbble.com/users/916693/screenshots/13263333/media/cbbc013b209b92b1774da8915ffbed1f.gif' },
        { title: 'Idea SideProject', description: 'Soy fan del Downhill MTB, muchos riders quisieran ver las diferencias las bicis ganadoras de la copa mundo, un histórico por año y sus características.', image: 'https://cdn.dribbble.com/users/916693/screenshots/10975535/media/7bac0f195de27f66df377276ffd04d9a.jpg' },
        { title: 'Hero diseño interiores', description: 'Un hero interactivo que permite descubrir con interacción las imágenes de diseños de interiores.', image: 'https://cdn.dribbble.com/users/916693/screenshots/11011972/media/74b8742568f13b554883fb387f7e848a.jpg' },
        { title: 'Parrallax Hero', description: 'Una transición en scroll usando el efecto Parrallax.', image: 'https://cdn.dribbble.com/users/916693/screenshots/15096289/media/406a840f996e7cc75b185bea5caddb61.gif' },
      ],
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  margin: 96px auto;
  max-width: 810px;
  text-align: center;
  

  &__head {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    align-items: flex-start;

    &__left {
      flex-basis: 280px;
      font-size: 22px;
      text-align: left;

      h1 {
        color: white;
        font-weight: 200;
        margin: 0px;
      }

      p {
        color: #888888;
        margin: 0px;
      }
    }

    &__right {
      color: white;
      display: flex;
      flex-direction: column;
      min-width: 320px;
      flex: 1;
      text-align: left;


      &__button {
        
        border: none;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        background-color: #42b983;
        padding: 10px 20px;
        text-decoration: none;
        text-align: center;
        padding: 16px 24px;
        width: 240px;


        &:hover {
          background-color: #2c8c6a;
        }
      }

      p  {
        font-size: 28px;
        margin: 0;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    margin-top: 96px;

    &__columns {
      flex: 1;
      flex-basis: 280px;
      border-radius: 16px;
      background-color: #FFFFFF;
      text-align: left;
      padding: 42px;

      img {
        width: 100%;
        border-radius: 20px;
      }

      h3 {
        font-size: 32px;
        margin: 32px 0 0 0;
      }
    }

    &__news-item {
      margin-top: 30px;
    }

  }

  &__playground {
    background-color: #2f2f2f;
    padding: 48px;
    margin-top: 40px;
    border-radius: 16px;
    color: white;
  
    h3 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 0;
    }

    h5 {
      font-size: 20px;
    }

    &__experiment {
      border-radius: 16px;
      border: 1px solid white;
      margin-top: 56px;
    }
  }

}
</style>